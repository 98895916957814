import 'magnific-popup';
import 'owl.carousel';
import moment from 'moment';

jQuery( document ).ready(function($) {
    $('.scroll').on('click', function(e) {
        var href   = $(this).attr('href');
        var target = href.substring(href.indexOf('#'));

        if($(target).length) {
            $('body, html').stop().animate({
                scrollTop: $(target).offset().top - 70
            }, 1000);

            e.preventDefault();
        }
    });

    $('#mobile-nav-btn').on('click', function(e) {
        $('nav').toggleClass('active');
        e.preventDefault();
    });

    $('nav a').on('click', function() {
        $('nav').removeClass('active');
    });

    // Spotify
    const SMESpotifyPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
            let hash;
            const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

            for (let i = 0; i < hashes.length; i++)
            {
                hash = hashes[i].split('=');
                this.url_vars.push(hash[0]);
                this.url_vars[hash[0]] = hash[1];
            }

            this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
            this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
            this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;

            if (typeof form_id !== 'undefined' && this.spotify_user !== null) {
                const form = document.getElementById(form_id);
                form.innerHTML = form.innerHTML + '<input type="hidden" id="spotify_user" name="spotify_user" value="' + this.spotify_user + '" />';
            }

            if (typeof callback === 'function') {
                callback(this.state, this.spotify_user, this.message);
            }
        }
    };

    SMESpotifyPreSave.getURLVars(function(state) {
        if(state === 'thank-you') {
            $('#popup').fadeIn();
        }
    });

    $('#close').on('click', function(e) {
        e.preventDefault();
        $('#popup').fadeOut();
    });

    // Newsletter
    $('.newsletter').on('submit', function(e) {
        var data = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: 'https://subs.sonymusicfans.com/submit/',
            dataType: 'json',
            data: data,
            success: function(data) {
                $('.newsletter').replaceWith('<span class="newsletter-thanks">Thanks for signing up!</span>');
            },
            error: function(err) {
                console.log(err);
            }
        });

        e.preventDefault();
    });

    const $owl = $('#carousel');

    $owl.on('initialized.owl.carousel', function() {
        $($owl.find('.active')[1]).addClass('second');
    });

    $owl.owlCarousel({
        items: 1,
        loop: true,
        margin: 20,
        responsive: {
            991: {
                stagePadding: 200
            },
            0: {
                stagePadding: 30
            }
        }
    });

    $(document).on('click', '.owl-item.active a', function(e) {
        $.magnificPopup.open({
            items: {
                src: $(this).attr('href')
            },
            type: 'iframe'
        });
    });

    $('.video-item a').on('click', function(e) { e.preventDefault(); });

    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/Buddy Guy/events?app_id=45PRESS_Buddy',
        method: 'GET',
        dataType: 'json',
        error: () => {
            alert( 'Error fetching events!' );
        },
        success: data => {
            const events = $( '#shows' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    let location = event.venue.city + ', ' + event.venue.region;
                    if ( location === ', ' ) {
                        location = '';
                    }
                    html += '<div class="event">';
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM D' ) + '</div>';
                    html += '<div class="event-location">' + location + '<span>'+ event.venue.name + '</span></div>';
                    html += '<div class="event-links">';
                    for ( let offer of event.offers ) {
                        if (offer.type === 'Tickets'){
                        html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>';
                        }
                    }
                    html += '</div>';
                    html += '</div>';
                }
                html+='<a class="event event-more" href="">See All Dates</a>';
                events.html( html );

                
                $('.event-more').on('click', function(e){
                    e.preventDefault();
                    $('#shows').toggleClass('seeall');
                })
            } else {
            }
        }
    });


    let feed_limit = 6;

    function getFeed() {
        // Instagram
        $.ajax({
            url: 'https://www.juicer.io/api/feeds/therealbuddyguy',
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                let c = 1;
                let html = '';
                const $feed = $('#feed, #feed-m');
                console.log(data);
                for(let item of data.posts.items) {
                    let text  = item.unformatted_message.replace(/<\/?[^>]+(>|$)/g, '');
                    const img = item.image;

                    if(img) {
                        html += `
                            <div>
                                <a href="${item.full_url}" target="_blank" style="background-image: url('${img}');">
                                    <span>${text}</span>
                                </a>
                            </div>
                        `;
                    } else {
                        html += `
                            <div>
                                <a href="${item.full_url}" target="_blank">
                                    <span style="opacity: 1;">${text}</span>
                                </a>
                            </div>
                        `;
                    }

                    if(feed_limit > 0 && c++ >= feed_limit) {
                        $feed.html(html);
                        return false;
                    }
                }

                $feed.html(html);
            },
            error: function () {
                console.log('Error loading feed.');
            }
        });
    }

    getFeed();

   
    $('.btn-apple').magnificPopup({
        items: {
            src: '#apple-popup',
            type: 'inline'
        }
    });

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    //Apple Form
    $('.btn-apple').on('click', function( e ) { 
        let AmkPlaylists = ["pl.a7c2781f1c4d4fe8b27f64b195c73efd"];
        let AmkAlbums = [];
        // Create a new SMEAppleMusic object with your parameters
        let sm = new SMEAppleMusic({
            ae: {
                ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
                brand_id: '3443715',
                segment_id: '1532848',
                activities: '{"actions":{"presave":94451},"mailing_list_optins":{"a0S61000001YpswEAC":94453}}'
            },
            am: {
                dev_token: '',
                save_mode: 'library',
                custom_playlist_name: '',
                resources: {
                playlists: AmkPlaylists,
                albums: AmkAlbums
                }
            },
            sf: {
                form: '201464',
                default_mailing_list: ''
            },
            smf: {
                campaign_id: '201464',
                campaign_key: '9c8f5ef683848cc360a87eda5a3b738a'
            }
        });
  
        document.getElementById('apple_music_form').addEventListener('submit', function(e) {
            e.preventDefault();
            // Grab the email address value
            const email = document.getElementById('apple_music_email_address').value;
            // Pass the email address and opt in status
            // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
            sm.doActions(email, false, ["a0S61000001YpswEAC"]).then(function (res) {
                // Complete - handle your logic here
                $.magnificPopup.open({
                    items: {
                      src: '#thankyou'
                    },
                    type: 'inline'
                });
                
            }).catch(function(err) {
                // Error - handle your logic here
            });
        });
    } );

    //Spotify
    let SMEPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
          let hash;
          let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
          for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            this.url_vars.push(hash[0]);
            this.url_vars[hash[0]] = hash[1];
              }
          this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
          this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
          this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
          this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
          this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
          if (typeof callback === 'function') {
            callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
          }
        }
      };
      /* String state
        *  The status of the pre-save.
        *  Int ae_member_id
        *  The Appreciation Engine member ID. This can be passed to the forms processor.
        *  String spotify_user
        *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
        *  String deezery_user
        *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
        */
      SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
        // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
        if (state === 'success' || state === 'thank-you') {
          $.magnificPopup.open({
            items: {
              src: '#thankyou'
            }, 
            type: 'inline'
          });
        }
    });    
});